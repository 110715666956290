import React, { Component } from "react";

import { connect } from "react-redux";
import {
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import CollectionCrud from "../../common/Components/CollectionCrud";
import { formatPoids, formatPrix, getDate } from "../../tools/Tools";
import CommAchatExploitationCss from "./css/CommAchatExploitationCss";
import {
  collectionActions,
  loadCollectionAttribute,
} from "../../../reducers/collectionsReducer";
import FilterDateSelecteur from "../../common/Components/FilterDateSelecteur";
import { buildRoute } from "../../../router/Tools";
import { CloudDownload } from "@material-ui/icons";
import { axiosClient } from "../../../axios";
import TextInput from "../../common/Components/TextInput";
import { hasRights } from "../../common/Tools/Tools";

const LOCALSTORAGE_FILTERS_KEY = "lot_comm_achat_exploitation_filters";

class CommAchatExploitation extends Component {
  state = {
    current_filter: {},
    localData: [],
    filterRecherche: "",
    type_affaire_slug: ["achat-exploitation",'taf-entrant'],
    type_filter: ["all"],
    commercial_achat_filter: ["all"],
    etat_filter: ["all"],
    famille_filter: ["all"],
    période: [],
    type_date_filter: "date_livraison",
    date_filter_date_fin: "",
    date_filter_date_debut: "",
    date_filter: [],
    refreshKey: 0,
    fetchRequest: null,
    fetching: false,
    focus_input: null,
    local_extra_marge: null,
    mois: null,
    local_stock_matiere_premiere_traitement_uuid: null,
    local_stock_matiere_premiere_uuid: null,
  };

  loadFromPropsvalues() {
    if (this.props.selectedValue === "mois") {
      this.setState(
        {
          commercial_achat_filter: [this.props.user_uuid ?? "all"],
          type_affaire_slug:["achat-exploitation",'taf-entrant'],
          date_filter: [],
          mois: this.props.mois,
          etat_filter: this.props.etat_default_value,
        },
        () => this.fetchLots()
      );
    } else {
      this.setState(
        {
          commercial_achat_filter: [this.props.user_uuid ?? "all"],
          type_affaire_slug: ["achat-exploitation",'taf-entrant'],
          date_filter: this.props.date_debut
            ? [this.props.date_debut, this.props.date_fin]
            : [],
          mois: null,
          etat_filter: this.props.etat_default_value,
        },
        () => this.fetchLots()
      );
    }
  }


  componentDidMount() {
    if (this.props.is_from_supervision) {
      this.loadFromPropsvalues();
    } else {
      this.loadFilters();
    }

    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "traitements",
      this.props.traitementStore
    );

    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "type_affaires",
      this.props.typeAffairesStore
    );
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "familles",
      this.props.famillesStore
    );
    loadCollectionAttribute(
      this.props.dispatch,
      "list",
      "etats",
      this.props.etatsStore
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.mois !== this.props.mois ||
      prevProps.date_debut !== this.props.date_debut ||
      prevProps.date_fin !== this.props.date_fin ||
      prevProps.user_uuid !== this.props.user_uuid
    ) {
      this.loadFromPropsvalues();
    }
  }

  saveFilters() {
    const {
      commercial_achat_filter,
      type_affaire_slug,
      type_date_filter,
      date_filter,
      famille_filter,
      etat_filter,
      filterRecherche,
    } = this.state;
    const filters = {
      commercial_achat_filter,
      type_affaire_slug,
      type_date_filter,
      date_filter,
      famille_filter,
      etat_filter,
      filterRecherche,
    };
    if(this.props.is_from_supervision){
      return
    }

    localStorage.setItem(LOCALSTORAGE_FILTERS_KEY, JSON.stringify(filters));
  }

  loadFilters() {
    const filters = JSON.parse(localStorage.getItem(LOCALSTORAGE_FILTERS_KEY));
    if (filters) {
      this.setState(filters, () => this.fetchLots());
    } else {
      this.fetchLots();
    }
  }

  fetchLots(timeout = 0) {
    const {
      commercial_achat_filter,
      type_affaire_slug,
      type_date_filter,
      date_filter,
      famille_filter,
      etat_filter,
      filterRecherche,
      mois,
    } = this.state;
    const params = {
      commercial_achat_filter,
      type_affaire_slug,
      type_date_filter,
      date_filter,
      famille_filter,
      etat_filter,
      filterRecherche,
      mois,
    };

    clearTimeout(this.state.fetchRequest);
    // eslint-disable-next-line
    this.setState({ fetching: true });
    this.state.fetchRequest = setTimeout(() => {
      collectionActions(this.props.dispatch, "lots", "INDEX_WITHOUT_DISPATCH", {
        params,
      }).then((response) => {
        this.setState({ localData: response.data, fetching: false });
      });
    }, timeout);
  }

  onChangeFilterHandler(name, e) {
    let values = e.target.value;

    if (values.length > 0) {
      if (this.state[name].indexOf("all") !== -1) {
        values = values.filter((value) => value !== "all");
      } else if (values.indexOf("all") !== -1) {
        values = ["all"];
      }
    } else {
      values = ["all"];
    }

    this.setState({ [name]: values }, () => {
      this.fetchLots();
      this.saveFilters();
    });
  }

  getCommercialAchatFilter() {
    const { commercial_achat_filter } = this.state;
    return this.generateCommercialSelect(
      commercial_achat_filter,
      "commercial_achat_filter",
      "Commercial Achat"
    );
  }

  generateCommercialSelect(value, name, label) {
    const { usersStore, classes } = this.props;
    if (!usersStore.list) return null;
    const commerciaux = [
      {
        uuid: "all",
        firstname: "Tous",
      },
      ...usersStore.list,
    ];
    const options = commerciaux.map((user) => {
      return (
        <MenuItem value={user.uuid} key={user.uuid}>
          {user.firstname}
        </MenuItem>
      );
    });
    return (
      <TextField
        id={name}
        label={label}
        margin="normal"
        onChange={this.onChangeFilterHandler.bind(this, name)}
        className={classes.selectContainer}
        SelectProps={{
          multiple: true,
          value: value,
        }}
        select
        disabled={this.props.is_from_supervision}
      >
        {options}
      </TextField>
    );
  }

  filterLocalData() {
    const { filterRecherche } = this.state;
    const { localData } = this.state;

    // Pour l'instant on ne filtre pas les données
    return localData;

    if (!filterRecherche) return localData;

    return localData.filter((mad) => {
      const keys = Object.keys(mad);
      return keys.some((key) => {
        if (typeof mad[key] === "string") {
          return mad[key].toLowerCase().includes(filterRecherche.toLowerCase());
        }
        return false;
      });
    });
  }

  getTypeAffaireFilter() {
    const { typeAffairesStore, classes, is_from_supervision } = this.props;
    const { type_affaire_slug } = this.state;
    if (!typeAffairesStore.list) return null;

    const typesAffaires = [
      {
        slug: "all",
        libelle: "Tous",
      },
      ...typeAffairesStore.list,
    ];
    const options = typesAffaires.map((typeAffaire) => {
      return (
        <MenuItem value={typeAffaire.slug} key={typeAffaire.slug}>
          {typeAffaire.libelle}
        </MenuItem>
      );
    });

    return (
      <TextField
        id="type_affaire_filter"
        label="Type Affaire"
        margin="normal"
        onChange={this.onChangeFilterHandler.bind(this, "type_affaire_slug")}
        className={classes.selectContainer}
        SelectProps={{
          multiple: true,
          value: type_affaire_slug,
        }}
        select
        disabled={is_from_supervision}
      >
        {options}
      </TextField>
    );
  }

  getFamilleFilter() {
    const { famillesStore, classes } = this.props;
    const { famille_filter } = this.state;
    if (!famillesStore.list) return null;
    const familles = [
      {
        uuid: "all",
        nom: "Toutes",
      },
      ...famillesStore.list,
    ];
    const options = familles.map((famille) => {
      return (
        <MenuItem value={famille.uuid} key={famille.uuid}>
          {famille.nom}
        </MenuItem>
      );
    });

    return (
      <TextField
        label="Famille"
        margin="normal"
        onChange={this.onChangeFilterHandler.bind(this, "famille_filter")}
        className={classes.selectContainer}
        SelectProps={{
          multiple: true,
          value: this.state.famille_filter,
        }}
        select
      >
        {options}
      </TextField>
    );
  }

  getEtatsFilter() {
    const { etatsStore, classes } = this.props;
    const { etat_filter } = this.state;
    if (!etatsStore.list) return null;
    const etats = [
      {
        uuid: "all",
        nom: "Tous",
      },
      ...etatsStore.list,
    ];
    const options = etats.map((etat) => {
      return (
        <MenuItem value={etat.uuid} key={etat.uuid}>
          {etat.nom}
        </MenuItem>
      );
    });

    return (
      <TextField
        id="etat_filter_affaire"
        label="État"
        margin="normal"
        onChange={this.onChangeFilterHandler.bind(this, "etat_filter")}
        className={classes.selectContainer}
        SelectProps={{
          multiple: true,
          value: etat_filter,
        }}
        select
      >
        {options}
      </TextField>
    );
  }

  getFilterRecherche() {
    const { classes, lotsStore } = this.props;
    const { filterRecherche } = this.state;

    return (
      <Grid item lg={2} xs={12}>
        <TextInput
          label="Recherche"
          className={classes.selectContainer}
          margin="normal"
          name="filterRecherche"
          value={filterRecherche}
          collectionStore={lotsStore}
          onChangeHandler={(name, e) => {
            this.setState({ [name]: e.target.value }, () => {
              this.fetchLots(500);
              this.saveFilters();
            });
          }}
          fullWidth
        />
      </Grid>
    );
  }

  getFilters() {
    const { classes, is_from_supervision } = this.props;
    const typeAffaireFilter = this.getTypeAffaireFilter();
    const etatFilter = this.getEtatsFilter();
    const commercialAchatFilter = this.getCommercialAchatFilter();
    const familleFilter = this.getFamilleFilter();
    const filtreRecherche = this.getFilterRecherche();

    if (!commercialAchatFilter) {
      return null;
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          ></Typography>
        </Grid>
        <Grid item xs={2}>
          {commercialAchatFilter}
        </Grid>
        <Grid item xs={2}>
          {typeAffaireFilter}
        </Grid>
        <Grid item xs={2}>
          {familleFilter}
        </Grid>
        <Grid item xs={2}>
          {etatFilter}
        </Grid>
        <Grid>
          {is_from_supervision ? null : (
            <FilterDateSelecteur
              onChangeHandlerFilters={this.onChangeFilterHandler.bind(this)}
              localStorageKey={LOCALSTORAGE_FILTERS_KEY}
              date_filter={this.state.date_filter}
              type_date_filter={this.state.type_date_filter}
              date_filter_date_debut={this.state.date_filter_date_debut}
              date_filter_date_fin={this.state.date_filter_date_fin}
            />
          )}
        </Grid>
        {filtreRecherche}
      </Grid>
    );
  }

  getRedirectButton(lot) {
    const { classes } = this.props;

    return (
      <span className={classes.reference} key={lot.uuid}>
        <a
          href={buildRoute("/affaires/:uuid", {
            uuid: lot.affaire_uuid,
          })}
          target="_blank"
        >
          {lot.affaire_reference}
        </a>
      </span>
    );
  }

  displayExtraMarge(lot, name) {
    const { lotStore, dispatch } = this.props;
    const { focus_input, local_extra_marge } = this.state;
    const input_key = name + lot.uuid;
    const value = lot[name];

    if (hasRights("commission-cud-extra-marge", this.props.user)) {
      return (
        <TextInput
          id={input_key}
          key={input_key}
          label="extra marge"
          value={input_key === focus_input ? local_extra_marge : value}
          onFocus={() => {
            this.setState({ focus_input: input_key, local_extra_marge: value });
          }}
          onBlur={() => {
            this.setState({ focus_input: null, local_extra_marge: null });
            if (local_extra_marge !== value) {
              collectionActions(
                dispatch,
                "lots",
                "UPDATE",
                {
                  ...lot,
                  extra_marge: local_extra_marge,
                },
                (lot) => {
                  if (lot) {
                    this.setState({
                      localData: this.state.localData.map((l) => {
                        if (l.uuid === lot.uuid) {
                          return lot;
                        }
                        return l;
                      }),
                    });
                  }
                }
              );
            }
          }}
          onChangeHandler={(name, e) => {
            this.setState({ local_extra_marge: e.target.value });
          }}
          collectionStore={lotStore}
          name="extra_marge"
        />
      );
    } else {
      return formatPrix(value, 2);
    }
  }

    // CODE A SUPPRIMER APRES DISCUSSION AVEC CHRISTOPHE SUR LE BESOIN REEL (COMPLEXE A GÉRER)

    // getTraitementOptions() {
    //   const { list } = this.props.traitementStore;

    //   if (!list) return [];

    //   return list.map((traitement) => {
    //     return (
    //       <MenuItem value={traitement.uuid} key={traitement.uuid}>
    //         {traitement.libelle}
    //       </MenuItem>
    //     );
    //   });
    // }

  // displayTraitementLibelle(lot, name) {
  //   const { lotStore, dispatch } = this.props;
  //   const { focus_input, local_stock_matiere_premiere_traitement_uuid,local_stock_matiere_premiere_uuid } = this.state;
  //   const input_key = name + lot.uuid;
  //   let value = lot[name];
  //   // return value;
  //   if (hasRights("commission-cud-extra-marge", this.props.user)) {
  //     value = lot["stock_matiere_premiere_traitement_uuid"];
  //     return (
  //       <TextInput
  //         select
  //         id={input_key}
  //         key={input_key}
  //         label="Traitement"
  //         value={input_key === focus_input ? local_stock_matiere_premiere_traitement_uuid : value}
  //         onFocus={() => {
  //           this.setState({ focus_input: input_key, local_stock_matiere_premiere_traitement_uuid: value,  local_stock_matiere_premiere_uuid : lot["stock_matiere_premiere_uuid"] });
  //         }}
  //         onChangeHandler={(name, e) => {
  //           this.setState({ local_stock_matiere_premiere_traitement_uuid: e.target.value });
  //           // if (local_stock_matiere_premiere_traitement_uuid !== value) {

  //             collectionActions(
  //               dispatch,
  //               "stock/matieres_premieres",
  //               "UPDATE",
  //               {
  //                 traitement_uuid : local_stock_matiere_premiere_traitement_uuid,
  //                 uuid: local_stock_matiere_premiere_uuid,
  //               },
  //               (lot) => {
  //                 console.log(lot)
  //               }
  //             );
  //         }}
  //         collectionStore={lotStore}
  //         name="stock_matiere_premiere_traitement_uuid"
  //       >
  //             {this.getTraitementOptions()}
  //       </TextInput>
  //     );
  //   } else {
  //     return value;
  //   }
  // }

  getCellsConfig() {
    return [
      {
        datakey: "commercial_initiales_achat",
        title: "Com. achat",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "affaire_date_livraison",
        title: "Date livraison",
        sortable: true,
        searchable: true,
        isDate: true,
        format: (obj, key) => {
          return getDate(obj[key]);
        },
      },
      {
        datakey: "affaire_reference",
        title: "Référence Affaire",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          return this.getRedirectButton(obj, key);
        },
      },
      {
        datakey: "affaire_fournisseur",
        title: "Fournisseur",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "article_famille_nom",
        title: "Famille",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "conditionnement_nom",
        title: "cdmt",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "article_nom",
        title: "Article",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "affaire_etat",
        title: "Etat",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "poids_achat",
        title: "PA (t)",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          return formatPoids(obj[key]);
        },
      },
      {
        datakey: "prix_unitaire_achat",
        title: "Px A (€/t)",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          return formatPrix(obj[key], 2);
        },
      },
      {
        datakey: "poids_vente",
        title: "PV (t)",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          return formatPoids(obj[key], 2);
        },
      },
      {
        datakey: "prix_unitaire_vente",
        title: "Px V (€/t)",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          return (
            <div
              style={{
                backgroundColor: obj["info_regroupement_color"],
                minHeight: "20px",
                padding: "10px",
              }}
              key={obj["uuid"]}
              title={obj["info_bulle_regroupement"]}
            >
              {formatPrix(obj[key], 2)}
            </div>
          );
        },
      },
      {
        datakey: "extra_marge",
        title: "Extra Marge (€/t)",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          return this.displayExtraMarge(obj, key);
        },
      },
      {
        datakey: "stock_matiere_premiere_traitement_libelle",
        title: "Traitement",
        sortable: true,
        searchable: true,
        // format: (obj, key) => {
        //   return this.displayTraitementLibelle(obj, key);
        // },
      },
      {
        datakey: "prix_transport_par_article",
        title: "TPT",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          return formatPrix(obj[key], 2);
        },
      },
      {
        datakey: "affaire_ca_facturation_annexe",
        title: "FA",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          return formatPrix(obj[key], 2);
        },
      },
      {
        datakey: "marge",
        title: "Article marge",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          return formatPrix(obj[key], 2);
        },
      },
      {
        datakey: "has_non_conformite",
        title: "NC",
        sortable: true,
        searchable: true,
        format: (obj, key) => {
          return obj[key] ? "Oui" : "Non";
        },
      }
    ];
  }

  getCrudTitle() {
    const { classes } = this.props;
    return [
      <Grid container className={classes.container}>
        <Grid item xs={3}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={6}></Grid>
      </Grid>,
    ];
  }

  generateHref() {
    const {
      commercial_achat_filter,
      type_affaire_slug,
      type_date_filter,
      date_filter,
      famille_filter,
      etat_filter,
      filterRecherche,
    } = this.state;

    const params = {
      commercial_achat_filter,
      type_affaire_slug,
      type_date_filter,
      date_filter,
      famille_filter,
      etat_filter,
      filterRecherche,
    };

    // generate string value for each param like  "?periode_anterieur=1"
    let paramsString = "";
    Object.keys(params).forEach((key) => {
      if (params[key].length > 0) {
        if (key === "date_filter") {
          paramsString += `&${key}=${params[key]}`;
        } else {
          paramsString += `&${key}=${params[key]}`;
        }
      }
    });
    return process.env.REACT_APP_API_URL + "/lots/export/" + paramsString;
  }

  exportComm() {
    const {
      commercial_achat_filter,
      type_affaire_slug,
      type_date_filter,
      date_filter,
      famille_filter,
      etat_filter,
      filterRecherche,
    } = this.state;

    let params = {
      commercial_achat_filter,
      type_affaire_slug,
      type_date_filter,
      date_filter,
      famille_filter,
      etat_filter,
      filterRecherche,
    };

    axiosClient
      .get(
        "/lots/export",
        { params: params },
        {
          responseType: "blob",
          headers: {
            Accept: "text/csv;charset=utf-8;",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        const contentDisposition = response.headers["content-disposition"];
        const fileName = contentDisposition
          .split(";")[1]
          .split("filename=")[1]
          .trim()
          .split('"')[1];

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  displayLoader() {
    const { classes } = this.props;
    const { fetching } = this.state;

    if (fetching) {
      return (
        <CircularProgress className={classes.loader} size={28} key={"loader"} />
      );
    } else {
      return null;
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.getFilters()}
        <IconButton
          title={"Export des commissions d'achat exploitation"}
          variant="contained"
          color="primary"
          onClick={this.exportComm.bind(this)}
          className={classes.button}
        >
          <CloudDownload />
        </IconButton>
        {this.displayLoader()}
        <CollectionCrud
          collectionName={"lots"}
          loadDatas={false}
          datas={this.filterLocalData()}
          showBtnEdit={false}
          showBtnAdd={false}
          showBtnDelete={false}
          modalMaxWidth="md"
          cancelUpdateCheck={true}
          datatableConfig={{
            showPagination: true,
            showSearch: false,
            nodatalabel: "Aucun lot d'article",
            rowsPerPageOptions: [50, 100],
          }}
          dataTableExtraNodes={[
            {
              element: this.getCrudTitle(),
              position: "top-left",
            },
          ]}
          cellsConfig={this.getCellsConfig()}
        />
      </>
    );
  }
}

CommAchatExploitation = withStyles(CommAchatExploitationCss)(
  CommAchatExploitation
);

CommAchatExploitation = connect((store) => {
  return {
    lotsStore: store.collections["lots"],
    usersStore: store.collections.users,
    typeAffairesStore: store.collections.type_affaires,
    famillesStore: store.collections.familles,
    etatsStore: store.collections.etats,
    user: store.auth.user,
    traitementStore: store.collections.traitements,
    matieresStore: store.collections["stock/matieres_premieres"],
  };
})(CommAchatExploitation);

export default CommAchatExploitation;
